<template>
  <div>
    <b-button
      v-b-modal.verify-account
      size="sm"
      :variant="isSuccess ? 'success' : 'outline-danger'"
    >
      {{ isSuccess ? 'Verified' : ' Verify account' }}
    </b-button>
    <b-modal
      id="verify-account"
      hide-header
      hide-footer
      size="xl"
    >
      <MobileBankingForm
        v-model="bankData"
        :is-loading="isLoading"
        @submit="onSubmit"
        @close="navigateRoute"
      />
    </b-modal>
    <b-modal
      v-model="isShowOTPModal"
      hide-header
      hide-footer
      no-close-on-backdrop
      no-close-on-esc
      size="xl"
    >
      <validation-observer ref="verifyScbForm">
        <b-form @submit.prevent="onSubmitVerifyOTP">
          <validation-provider
            v-slot="{ errors }"
            name="otp"
            rules="required"
          >
            <b-form-group label="OTP">
              <b-form-input
                v-model="otp"
                :state="errors[0] ? false : null"
                :aria-invalid="false"
                placeholder="OTP"
                min="6"
                type="text"
              />
              <b-form-invalid-feedback>
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-button
            variant="success"
            type="submit"
          >
            {{
              $t('buttons.submit')
            }}
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    MobileBankingForm: () => import('./MobileBankingForm'),
  },
  props: {
    bankAccountId: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      required,
      isSuccess: false,
      isCheck: false,
      otp: '',
      isLoading: false,
      bankData: null,
      showOTP: false,
      isShowOTPModal: false,
      isShowMobileBankingFormModal: false
    }
  },
  computed: {
    ...mapState({
      isRegisteringSCBSuccess: (state) => state.user.isRegisteringSCBSuccess,
      isVerifyingOTPSuccess: (state) => state.user.isVerifyingOTPSuccess,
    }),
  },
  watch: {
    isRegisteringSCBSuccess(val) {
      if (val) {
        // open verify otp
        this.isShowOTPModal = true
      }
    },
    isVerifyingOTPSuccess(val) {
      if (val) {
        this.isShowOTPModal = false
        this.$bvModal.hide('verify-account')
      }
    },
  },
  methods: {
    ...mapActions(['registerSCB', 'verifyScbOTP']),
    onCancel() {
      this.$emit('close')
    },
    navigateRoute() {
      this.$router.push('/bank-truewallets')
    },
    onSubmit(data) {
      this.onRegisterSCB(data)
    },
    onRegisterSCB(data) {
      this.registerSCB({ id: this.bankAccountId, data })
    },
    onSubmitVerifyOTP() {
      this.$refs.verifyScbForm.validate().then( async (success) => {
        if (success) {
          this.verifyScbOTP({ id: this.bankAccountId, data: { otp: this.otp } })
        }
      })
    },
  },
}
</script>
